import React, { Fragment } from 'react'
import { Row, Col, Divider } from 'antd'

export const Apply = () => {
  return (
    <Fragment>
      <div className='container page'>
        <h1 className='text-center'>Apply to the Adult Spine Fellowship</h1>
        <div className='section-inner'>
          <Row align='middle' gutter={32}>
            <Col xl={12}>
              <picture>
                <source srcSet={'/images/fellowship-apply.webp'} type='image/webp' />
                <img
                  srcSet={'/images/fellowship-apply.jpg'}
                  alt='clinic1'
                  className='clinic-img mobile-hidden'
                />
              </picture>
            </Col>
            <Col xl={12}>
              <div className='text-center'>
                <h3>Dr. Fred Nicholls, MD MA FRCSC</h3>
                <h4 className='muted'>Office of Surgical Fellowship (OSF)</h4>
                <h4 className='muted'>Department of Surgery</h4>
                <br />
                <h6>Email: nomathemba.sibanda@ucalgary.ca</h6>
              </div>
            </Col>
          </Row>
          <h4 className='text-left' style={{ marginTop: '3rem' }}>
            We are currently accepting applications for 2025-2026.
          </h4>
          <Divider />
          <h5>
            Did you complete your residency training from a North American Institution?
          </h5>
          <a href='https://www.sfmatch.org/' target='_blank' rel='noopener noreferrer'>
            Apply through SF Match
          </a>
          <br />
          <br />
          <span>Application registration begins in August 2024</span>
          <br />
          <span>
            Please target to have you application requirements completed by early
            September 2023
          </span>
          <br />
          <span>Interviews will take place after December 2024 </span>
          <br />
          <span>
            Please review the SF match website for further details and specific dates as
            they are announced.
          </span>
          <br />
          <span>
            Rank lists are submitted in April 2025 and Match results are released shortly
            after.{' '}
          </span>
          <br />
          <br />
          <br />
          <h5>Did you complete your residency training outside North America?</h5>
          <span>
            Apply directly to our program by emailing: nomathemba.sibanda@ucalgary.ca
          </span>
          <br />
          <br />
          <span>Application deadline is December 20, 2024</span>
          <br />
          <span>Occasionally vacancies are available, please enquire.</span>
        </div>
      </div>
    </Fragment>
  )
}
