import React, { Fragment } from 'react'

import { MeetTheTeam } from '../../components/MeetTheTeam'

export const Team = () => {
  return (
    <Fragment>
      <div className='page'>
        <MeetTheTeam />
      </div>
    </Fragment>
  )
}
