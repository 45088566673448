import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import Header from './components/navigation/Header'
import { Footer } from './components/Footer'
import routes from './routes'

const App = () => {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <div style={{ overflowX: 'hidden' }}>
      <Header />
      <div style={{ minHeight: '45vh' }}>{routes}</div>
      <Footer />
    </div>
  )
}

export default App
