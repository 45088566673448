import React, { Fragment } from 'react'
import { Result } from 'antd'
import { ClockCircleOutlined } from '@ant-design/icons'

export const ComingSoon = (props) => {
  const { title, description } = props

  return (
    <Fragment>
      <h1 className='text-center'>{title}</h1>
      <div className='section-inner'>
        <Result
          icon={<ClockCircleOutlined style={{ color: '#1890ff' }} />}
          title={<h3>Coming Soon!</h3>}
          extra={<h5 className='muted'>{description}</h5>}
        />
      </div>
    </Fragment>
  )
}
