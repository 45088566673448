import React, { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { DesktopHeader } from './DesktopHeader'
import { MobileHeader } from './MobileHeader'
import { isMobile } from 'src/constants'

const Header = () => {
  const [headerTransparent, setHeaderTransparent] = useState(true)
  const location = useLocation()

  const memoHeaderScroll = useCallback(() => {
    if (window.scrollY > 100) setHeaderTransparent(false)
    else setHeaderTransparent(true)
  })

  useEffect(() => {
    window.addEventListener('scroll', memoHeaderScroll)

    return () => {
      window.removeEventListener('scroll', memoHeaderScroll)
    }
  }, [])

  return (
    <div className={headerTransparent ? 'header' : 'header header-scroll'}>
      <div className='container'>
        {isMobile ? (
          <MobileHeader path={location.pathname} />
        ) : (
          <DesktopHeader path={location.pathname} />
        )}
      </div>
    </div>
  )
}

export default Header
