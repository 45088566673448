import React, { Fragment } from 'react'
import { Timeline, Collapse, Row, Col } from 'antd'
import { Link } from 'react-router-dom'

export const Roadmap = () => {
  return (
    <Fragment>
      <div className='container page'>
        <h1 className='text-center'>A Message from the Research Director</h1>
        <div className='section-inner'>
          <p>
            The University of Calgary Spine program has designed a Scientific Registry for
            the study of Spinal Disorders. Registered patients will be able to provide
            their data to help answer patient-centred questions. A portion of your
            de-identified anonymous data may be shared with the Canadian Spine Outcome and
            Research Network which is a group of more than 50 spine surgeons representing
            more than 18 hospitals across Canada to facilitate multi-centre spine surgery
            studies.
          </p>
          <p>
            This platform is designed to provide our clinicians and researchers the
            opportunity to continuously improve patient care and offer patients real-time
            data about their diagnosis.
          </p>
          <p>
            Our trainees, graduate students, residents and fellows will gain expertise in
            data science and have the opportunity to publish original work from the data
            contained in our registry.
          </p>
          <br />
          <Row align='middle' gutter={32}>
            <Col xl={4} lg={4} md={6} sm={8} xs={12}>
              <picture>
                <source srcSet={'/images/team/evaniew.webp'} type='image/webp' />
                <img
                  srcSet={'/images/team/evaniew.jpg'}
                  alt='portrait'
                  className='team-img'
                />
              </picture>
            </Col>
            <Col>
              <i>Dr. Nathan Evaniew</i>
              <br />
              <i>Research Director, University of Calgary Spine Program</i>
            </Col>
          </Row>
        </div>
        <div className='section'>
          <h1 className='text-center'>Roadmap</h1>
          <div className='section-inner'>
            <Row>
              <Col xl={3}>
                <picture>
                  <source srcSet={'/images/spine.webp'} type='image/webp' />
                  <img
                    src={'/images/spine.png'}
                    className='spine-img mobile-hidden'
                    alt='spine'
                  />
                </picture>
              </Col>
              <Col
                xl={21}
                style={{ display: 'flex', maxHeight: '600px', overflowY: 'scroll' }}
              >
                <Timeline>
                  <Timeline.Item>
                    <Collapse
                      bordered={false}
                      style={{ backgroundColor: '#fff', border: 'none' }}
                      defaultActiveKey='1'
                    >
                      <Collapse.Panel
                        header='Triage'
                        key='1'
                        style={{
                          backgroundColor: '#fff',
                          border: 'none',
                          padding: 'none',
                        }}
                      >
                        <div className='section-inner'>
                          <ul>
                            <li>
                              <span>
                                Disorders of the human spine are common in our society and
                                can have a dramatic impact on our quality of life
                              </span>
                            </li>
                            <li>
                              <span>
                                We are building a set of tools to help patients understand
                                their symptoms and determine if surgery may be an option
                                to improve their quality of life
                              </span>
                            </li>
                            <li>
                              <Link to='/patient/assessment'>Take Assessment</Link>
                            </li>
                          </ul>
                        </div>
                      </Collapse.Panel>
                    </Collapse>
                  </Timeline.Item>
                  <Timeline.Item>
                    <Collapse
                      bordered={false}
                      style={{ backgroundColor: '#fff', border: 'none' }}
                    >
                      <Collapse.Panel
                        header='Cervical Radiculopathy'
                        key='1'
                        style={{
                          backgroundColor: '#fff',
                          border: 'none',
                          padding: 'none',
                        }}
                      >
                        <div className='section-inner'>
                          <ul>
                            <li>
                              <span>
                                This is a term used to describe symptoms originating from
                                nerves as the exit the spinal cord and travel through your
                                neck and into your arms
                              </span>
                            </li>
                            <li>
                              <span>
                                Symptoms might include pain, numbness, tingling or even
                                weakness in specific muscles
                              </span>
                            </li>
                            <li>
                              <span>
                                An MRI is used to determine if surgery might help relieve
                                your symptoms
                              </span>
                            </li>
                          </ul>
                        </div>
                      </Collapse.Panel>
                    </Collapse>
                  </Timeline.Item>
                  <Timeline.Item>
                    <Collapse
                      bordered={false}
                      style={{ backgroundColor: '#fff', border: 'none' }}
                    >
                      <Collapse.Panel
                        header='Cervical Myelopathy'
                        key='1'
                        style={{
                          backgroundColor: '#fff',
                          border: 'none',
                          padding: 'none',
                        }}
                      >
                        <div className='section-inner'>
                          <ul>
                            <li>
                              <span>
                                This is a term used to describe a constellation of
                                symptoms that occur from repetitive spinal cord injury
                                when discs and ligaments loose structural integrity
                              </span>
                            </li>
                            <li>
                              <span>
                                Symptoms typically appear gradually over months to years
                              </span>
                            </li>
                            <li>
                              <span>
                                Affected patients have difficulty using their hands,
                                balance and walking
                              </span>
                            </li>
                            <li>
                              <span>
                                There is a spectrum of mild, moderate and severe forms of
                                the disease
                              </span>
                            </li>
                            <li>
                              <span>
                                Surgery can be very effective for this condition, in fact,
                                about 65% of our patients who undergo surgery improve in
                                their functional ability
                              </span>
                            </li>
                            <li>
                              <span>
                                We are involved in several local and national studies to
                                further improve our understanding of this condition
                              </span>
                            </li>
                            <li>
                              <span>
                                After receiving this diagnosis your surgeon will inform
                                you if you are eligible to participate in this study
                              </span>
                            </li>
                          </ul>
                        </div>
                      </Collapse.Panel>
                    </Collapse>
                  </Timeline.Item>
                  <Timeline.Item>
                    <Collapse
                      bordered={false}
                      style={{ backgroundColor: '#fff', border: 'none' }}
                    >
                      <Collapse.Panel
                        header='Lumbar Radiculopathy aka Sciatica'
                        key='1'
                        style={{
                          backgroundColor: '#fff',
                          border: 'none',
                          padding: 'none',
                        }}
                      >
                        <div className='section-inner'>
                          <ul>
                            <li>
                              <span>
                                Sciatica is a very common problem in our society
                              </span>
                            </li>
                            <li>
                              <span>
                                This is a painful disorder that usually results from
                                compression of a nerve that runs from your lower back down
                                your leg
                              </span>
                            </li>
                            <li>
                              <span>
                                Fortunately, most cases get better within weeks to months
                              </span>
                            </li>
                            <li>
                              <span>
                                If symptoms don’t improve, an MRI might be obtained to
                                confirm the diagnosis and determine if surgery might be
                                able to help you
                              </span>
                            </li>
                            <li>
                              <span>
                                We are currently running a study to determine the
                                characteristics of patients that get better on their own
                                and those that go on to have surgical treatment{' '}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </Collapse.Panel>
                    </Collapse>
                  </Timeline.Item>
                  <Timeline.Item>
                    <Collapse
                      bordered={false}
                      style={{ backgroundColor: '#fff', border: 'none' }}
                    >
                      <Collapse.Panel
                        header='Cauda Equina Syndrome'
                        key='1'
                        style={{
                          backgroundColor: '#fff',
                          border: 'none',
                          padding: 'none',
                        }}
                      >
                        <div className='section-inner'>
                          <ul>
                            <li>
                              <span>
                                This is a serious syndrome that results in numbness in the
                                groin area and difficulty with bowel, bladder and sexual
                                function
                              </span>
                            </li>
                            <li>
                              <span>
                                It usually comes on quickly and can be accompanied by pain
                                shooting down one or both legs
                              </span>
                            </li>
                            <li>
                              <span>
                                If you experience this you should talk to you doctor
                                immediately or go to an emergency room
                              </span>
                            </li>
                          </ul>
                        </div>
                      </Collapse.Panel>
                    </Collapse>
                  </Timeline.Item>
                  <Timeline.Item>
                    <Collapse
                      bordered={false}
                      style={{ backgroundColor: '#fff', border: 'none' }}
                    >
                      <Collapse.Panel
                        header='Lumbar Stenosis'
                        key='1'
                        style={{
                          backgroundColor: '#fff',
                          border: 'none',
                          padding: 'none',
                        }}
                      >
                        <div className='section-inner'>
                          <ul>
                            <li>
                              <span>
                                This is a degenerative condition of your lumbar spine that
                                results in compression of multiple nerves
                              </span>
                            </li>
                            <li>
                              <span>
                                It can result in varying symptoms to your legs such as
                                numbness, pain or a heavy feeling
                              </span>
                            </li>
                            <li>
                              <span>
                                These symptoms often get worse when you walk for longer
                                distances
                              </span>
                            </li>
                            <li>
                              <span>
                                An MRI is required to make this diagnosis and surgical
                                treatment can dramatically improve the quality of life in
                                some people
                              </span>
                            </li>
                            <li>
                              <span>
                                Our team is engaged in a national study to help advance
                                our knowledge of lumbar spinal disorders
                              </span>
                            </li>
                          </ul>
                        </div>
                      </Collapse.Panel>
                    </Collapse>
                  </Timeline.Item>
                  <Timeline.Item>
                    <Collapse
                      bordered={false}
                      style={{ backgroundColor: '#fff', border: 'none' }}
                    >
                      <Collapse.Panel
                        header='Spinal Deformity'
                        key='1'
                        style={{
                          backgroundColor: '#fff',
                          border: 'none',
                          padding: 'none',
                        }}
                      >
                        <div className='section-inner'>
                          <ul>
                            <li>
                              <span>
                                Walking with an upright posture is unique to human beings
                              </span>
                            </li>
                            <li>
                              <span>
                                Under rare circumstances the human spine can no longer
                                support an upright posture that allows us to walk with a
                                fluid gait
                              </span>
                            </li>
                            <li>
                              <span>
                                We have several surgeons with expertise in spinal
                                deformity
                              </span>
                            </li>
                          </ul>
                        </div>
                      </Collapse.Panel>
                    </Collapse>
                  </Timeline.Item>
                  <Timeline.Item>
                    <Collapse
                      bordered={false}
                      style={{ backgroundColor: '#fff', border: 'none' }}
                    >
                      <Collapse.Panel
                        header='Spinal Infection'
                        key='1'
                        style={{
                          backgroundColor: '#fff',
                          border: 'none',
                          padding: 'none',
                        }}
                      >
                        <div className='section-inner'>
                          <ul>
                            <li>
                              <span>
                                Spinal infection occurs when bacteria or other organisms
                                grow in the bones or spaces around the spinal cord
                              </span>
                            </li>
                            <li>
                              <span>The primary treatment is antibiotics</span>
                            </li>
                            <li>
                              <span>Surgery is occasionally necessary </span>
                            </li>
                          </ul>
                        </div>
                      </Collapse.Panel>
                    </Collapse>
                  </Timeline.Item>
                  <Timeline.Item>
                    <Collapse
                      bordered={false}
                      style={{ backgroundColor: '#fff', border: 'none' }}
                    >
                      <Collapse.Panel
                        header='Spinal Tumors'
                        key='1'
                        style={{
                          backgroundColor: '#fff',
                          border: 'none',
                          padding: 'none',
                        }}
                      >
                        <div className='section-inner'>
                          <ul>
                            <li>
                              <span>
                                The human spine can be infiltrated by tumors that might
                                cause pain, difficulty walking or other symptoms
                              </span>
                            </li>
                            <li>
                              <span>
                                Our multi-disciplinary team of surgeons, oncologists and
                                radiation-oncologists work together to create a
                                personalized treatment plan for each patient
                              </span>
                            </li>
                          </ul>
                        </div>
                      </Collapse.Panel>
                    </Collapse>
                  </Timeline.Item>
                  <Timeline.Item>
                    <Collapse
                      bordered={false}
                      style={{ backgroundColor: '#fff', border: 'none' }}
                    >
                      <Collapse.Panel
                        header='Traumatic Spinal Cord Injury '
                        key='1'
                        style={{
                          backgroundColor: '#fff',
                          border: 'none',
                          padding: 'none',
                        }}
                      >
                        <div className='section-inner'>
                          <ul>
                            <li>
                              <span>
                                Traumatic events such as motor vehicle accidents, skiing
                                accidents or other high energy activities can cause a
                                sudden fracture of the spinal column
                              </span>
                            </li>
                            <li>
                              <span>
                                This results in immediate damage to the spinal cord
                              </span>
                            </li>
                            <li>
                              <span>
                                This devastating event can result in immediate loss of the
                                ability to move your arms, legs or both
                              </span>
                            </li>
                            <li>
                              <span>
                                Our specialised team of surgeons and rehabilitation
                                doctors are ready to help people through this devastating
                                experience
                              </span>
                            </li>
                          </ul>
                        </div>
                      </Collapse.Panel>
                    </Collapse>
                  </Timeline.Item>
                  <Timeline.Item>
                    <Collapse
                      bordered={false}
                      style={{ backgroundColor: '#fff', border: 'none' }}
                    >
                      <Collapse.Panel
                        header='Tissue Bank'
                        key='1'
                        style={{
                          backgroundColor: '#fff',
                          border: 'none',
                          padding: 'none',
                        }}
                      >
                        <div className='section-inner'>
                          <ul>
                            <li>
                              <span>
                                The university of calgary spine program is embarking on a
                                unique project to help discover the factors underlying
                                spinal disorders
                              </span>
                            </li>
                            <li>
                              <span>
                                Through specific research projects we are investigating
                                blood samples or spinal tissue to help improve diagnosis
                                and prognostic predictions{' '}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </Collapse.Panel>
                    </Collapse>
                  </Timeline.Item>
                  <Timeline.Item>
                    <Collapse
                      bordered={false}
                      style={{ backgroundColor: '#fff', border: 'none' }}
                    >
                      <Collapse.Panel
                        header='Imaging Repository '
                        key='1'
                        style={{
                          backgroundColor: '#fff',
                          border: 'none',
                          padding: 'none',
                        }}
                      >
                        <div className='section-inner'>
                          <ul>
                            <li>
                              <span>
                                Imaging acquisition and advanced analytics is an active
                                area of research at the UofC
                              </span>
                            </li>
                            <li>
                              <span>
                                We are currently using Canada’s supercomputer (
                                <a
                                  href='https://www.computecanada.ca/'
                                  target='_blank'
                                  rel='noopener noreferrer'
                                >
                                  https://www.computecanada.ca/
                                </a>
                                ) to develop deep learning algorithms with our imaging
                                partners in Montreal, Canada (
                                <a
                                  href='https://www.opensourceimaging.org/project/spinal-cord-toolbox/'
                                  target='_blank'
                                  rel='noopener noreferrer'
                                >
                                  https://www.opensourceimaging.org/project/spinal-cord-toolbox/
                                </a>
                                )
                              </span>
                            </li>
                            <li>
                              <span>
                                We are developing a national imaging repository to
                                facilitate advanced analytics across spinal centers
                              </span>
                            </li>
                          </ul>
                        </div>
                      </Collapse.Panel>
                    </Collapse>
                  </Timeline.Item>
                </Timeline>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
