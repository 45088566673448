import React from 'react'
import { Result, Button } from 'antd'

import { useNavigate } from 'react-router-dom'

const NotFound = () => {
  const navigate = useNavigate()

  return (
    <div style={{ padding: '5rem' }}>
      <Result
        status='404'
        title='404'
        subTitle="Oops, that page doesn't seem to exist."
        extra={
          <Button type='primary' shape='round' size='large' onClick={() => navigate('/')}>
            Take me home
          </Button>
        }
      />
    </div>
  )
}

export default NotFound
