import React, { Fragment } from 'react'
import { Row, Col } from 'antd'

export const ChairmanMessage = () => {
  return (
    <Fragment>
      <div className='container page'>
        <h1 className='text-center'>A Message from the Chairman</h1>
        <div className='section-inner'>
          <p>
            The University of Calgary Spine Program is a multidisciplinary clinical and
            academic group focused on the care of individuals affected by conditions and
            diseases of the spine and spinal cord.
          </p>
          <p>
            Our mission is to provide world quality health care to individuals with spinal
            disorders through the pursuit of excellence in research, teaching and bedside
            clinical care.
          </p>
          <p>
            The program is centered at the Foothills Hospital and Alberta Children’s
            Hospital within Alberta Health Services.
          </p>
          <p>
            Members of the Spine Program have joint appointments in the Division of
            Neurosurgery, Department of Clinical Neurosciences and Division of Orthopedic
            Surgery, Department of Surgery. The Spine Program provides care for patients
            with spinal injury, infection, neoplasia and degenerative disease. Clinical
            care is closely linked to clinical education and research in a supportive
            academic setting.
          </p>
          <p>
            The program has representation from Neurological Surgery, Orthopedic Surgery,
            Nursing, and Orthotics. The program offers Spine Surgery Fellowship, combining
            Orthopaedic and Neurosurgical clinical experiences. Both Foothills Medical
            Centre and Alberta Children’s Hospital are tertiary referral centre for
            southern Alberta, southwestern Saskatchewan and southeastern British Columbia.
            The Spine Program also provides support for other hospital programs such as
            Trauma, Cancer and Bone and Joint.
          </p>
          <br />
          <Row align='middle' gutter={32}>
            <Col xl={4} lg={4} md={6} sm={8} xs={12}>
              <picture>
                <source srcSet={'/images/team/jacobs.webp'} type='image/webp' />
                <img
                  srcSet={'/images/team/jacobs.jpg'}
                  alt='portrait'
                  className='team-img'
                />
              </picture>
            </Col>
            <Col>
              <i>Dr. W. Bradley Jacobs</i>
              <br />
              <i>Chairman, University of Calgary Spine Program</i>
            </Col>
          </Row>
        </div>
      </div>
    </Fragment>
  )
}
