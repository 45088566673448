import React from 'react'
import { Route, Routes } from 'react-router-dom'

import NotFound from './pages/NotFound'
import { ChairmanMessage } from './pages/about/ChairmanMessage'
import { Contact } from './pages/about/Contact'
import { Team } from './pages/about/Team'
import { Apply } from './pages/fellowship/Apply'
import { DirectorMessage } from './pages/fellowship/DirectorMessage'
import { PastFellows } from './pages/fellowship/PastFellows'
import { Testimonials } from './pages/fellowship/Testimonials'
import { Home } from './pages/Home'
import { Login } from './pages/Login'
import { PatientAssessment } from './pages/PatientAssessment'
import { PatientPathway } from './pages/PatientPathway'
import CAPPS from './pages/research/CAPPS'
import { Roadmap } from './pages/research/Roadmap'
import { Careers } from './pages/about/Careers'
import { Documents } from './pages/about/Documents'

export default (
  <Routes>
    <Route path='/' element={<Home />} />
    <Route path='/about/message' element={<ChairmanMessage />} />
    <Route path='/about/team' element={<Team />} />
    <Route path='/about/contact' element={<Contact />} />
    <Route path='/about/careers' element={<Careers />} />
    <Route path='/about/documents' element={<Documents />} />
    <Route path='/fellowship/message' element={<DirectorMessage />} />
    <Route path='/fellowship/apply' element={<Apply />} />
    <Route path='/fellowship/testimonials' element={<Testimonials />} />
    <Route path='/fellowship/past-fellows' element={<PastFellows />} />
    <Route path='/research/roadmap' element={<Roadmap />} />
    <Route path='/research/capps' element={<CAPPS />} />
    <Route path='/patient/pathway' element={<PatientPathway />} />
    <Route path='/patient/assessment' element={<PatientAssessment />} />
    <Route path='/login' element={<Login />} />
    <Route path='*' element={<NotFound />} />
  </Routes>
)
