import React, { Fragment } from 'react'
import { Row, Col } from 'antd'

export const DirectorMessage = () => {
  return (
    <Fragment>
      <div className='container page'>
        <h1 className='text-center'>A Message from the Director</h1>
        <div className='section-inner'>
          <p>
            Spine Fellowship at the University of Calgary is a combined orthopaedic and
            neurosurgical clinical experience. The goals of the fellowship are to train
            neurosurgeons and orthopaedic surgeons in the diagnosis and management of
            diseases of the spine.
          </p>
          <p>
            The University of Calgary Spine program is an AO sponsored fellowship program.
            Our fellows participate in the annual AOSpine fellows forum and are exposed to
            other global leaders in spine surgery.{' '}
            <a
              href='https://aospine.aofoundation.org/education/fellowship'
              target='_blank'
              rel='noopener noreferrer'
            >
              Learn more about the AOSpine fellowships.
            </a>
          </p>
          <p>
            The University of Calgary Spine Program is a multidisciplinary group dedicated
            to the management of individuals with spinal disease. The program is based at
            Foothills Medical Centre and the Alberta Children’s Hospital, Level I
            trauma/tertiary care centres serving the population of the city of Calgary
            (1.4 million) and the surrounding regions of southern Alberta, southeastern
            British Columbia and southern Saskatchewan (total 2.0 million). There are an
            estimated 1600 spinal procedures performed per year at Foothills Medical
            Centre. In addition, all adult spinal cord injuries are seen and managed in
            the acute and rehabilitation stages at Foothills Medical Centre.
          </p>
          <p>
            The emphasis of this spine fellowship is upon the decision-making process and
            the surgical techniques relevant to the full breadth of spine surgery. At the
            end of the fellowship, the trainee will have the experience and ability to
            independently perform a full spectrum of surgical approaches, decompressions,
            deformity correction and instrumentation in all regions of the spine from the
            occiput to the pelvis.
          </p>
          <p>
            Our Fellows train in a wide variety of techniques:
            <ul>
              <li>Anterior and Posterior decompression</li>
              <li>
                Exposure to multiple different instrumentation systems
                <ul>
                  <li>Anterior and Posterior</li>
                  <li>Cervical, Thoracic and Lumbar</li>
                </ul>
              </li>
              <li>Cervical and Lumbar disc arthroplasty</li>
              <li>Open and less invasive procedures</li>
              <li>Image guidance/computer-aided surgery</li>
              <li>Intra-operative MRI</li>
            </ul>
          </p>
          <p>
            The Spine Fellowship year runs from July 1 to June 30 of the following year.
            Educational conferences include a weekly Fellows Conference, during which in
            depth didactic presentations on a wide range of spinal conditions are
            presented, as well as a weekly case conference with discussion and
            presentation of recent cases and the complex management thereof. The fellow
            can choose to attend either weekly Orthopaedic or Neurosurgical grand rounds.
            Regular monthly mortality and morbidity rounds, and a monthly journal club
            also take place.
          </p>
          <p>
            Weekly outpatient clinics are performed with the attending staff and informal
            teaching occurs with junior house staff and residents. Spine Service call
            occurs for each attending for one week in duration. During this time the house
            staff and Fellows assigned to the attending surgeon form the spine team on
            call. Initial assessment and management decisions are made by the resident and
            fellow with close supervision by the attending staff. All fellow call is at
            home call with no in-hospital responsibilities. During the course of the year,
            a clinical research project is required and must be completed prior to the
            conclusion of the training year. This research project is mutually agreed upon
            by the fellow and the supervisor of the fellow’s choice.
          </p>
          <br />
          <Row align='middle' gutter={32}>
            <Col xl={4} lg={4} md={6} sm={8} xs={12}>
              <picture>
                <source srcSet={'/images/team/nicholls.webp'} type='image/webp' />
                <img
                  srcSet={'/images/team/nicholls.jpg'}
                  alt='portrait'
                  className='team-img'
                />
              </picture>
            </Col>
            <Col>
              <i>Dr. Fred Nicholls</i>
              <br />
              <i>Fellowship Director, University of Calgary Spine Program</i>
            </Col>
          </Row>
        </div>
      </div>
    </Fragment>
  )
}
