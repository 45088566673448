import React, { Component, Fragment } from 'react'
import { Row, Col, Card } from 'antd'

import { CAPPSForm } from '../../components/forms/CAPPSForm'

export default class CAPPS extends Component {
  constructor(props) {
    super(props)

    this.state = {
      capps_score: null,
      capps_title: '',
      capps_description: '',
      loading: false,
    }
  }

  calculateScore = (values) => {
    let capps_score = 0
    let capps_title = ''
    let capps_description = ''

    this.setState({ loading: true })

    setTimeout(() => {
      if (values.age < 70) capps_score += 2
      if (values.sex === 'female') capps_score += 2
      if (values.opiod_daily === 'yes') capps_score += 3
      if (values.pain > 7) capps_score += 1
      if (values.phq_9_score > 9) capps_score += 1
      if (values.number_spinal_levels > 2) capps_score += 2
      if (values.fusion_surgery === 'yes') capps_score += 2

      if (capps_score >= 0 && capps_score <= 4) {
        capps_title = <b style={{ color: '#73d13d' }}>Low Risk</b>
        capps_description =
          '32.0% predicted probability of poor pain control on postoperative day 1'
      } else if (capps_score >= 5 && capps_score <= 8) {
        capps_title = <b style={{ color: '#ffa940' }}>High Risk</b>
        capps_description =
          '63.0% predicted probability of poor pain control on postoperative day 1'
      }
      if (capps_score >= 9 && capps_score <= 13) {
        capps_title = <b style={{ color: '#ff4d4f' }}>Extreme Risk</b>
        capps_description =
          '85.1% predicted probability of poor pain control on postoperative day 1'
      }

      this.setState({
        capps_score: capps_score,
        capps_title: capps_title,
        capps_description: capps_description,
        loading: false,
      })
    }, 1000)
  }

  render() {
    const { capps_score, capps_title, capps_description, loading } = this.state

    return (
      <Fragment>
        <div className='container page'>
          <h1 className='text-center'>
            The Calgary Postoperative Pain After Spine Surgery Score
          </h1>
          <div className='section-inner'>
            <p>
              Pain after spinal surgery is expected. However, some patients are more
              likely to experience uncontrolled pain after surgery than others. We
              developed the “Calgary Postoperative Pain After Spine Surgery (CAPPS) Score”
              that can accurately predict who is more likely to develop a poor pain
              experience after their spinal operation.
            </p>
            <p>
              Use the calculator below to determine a patient's chance of developing poor
              postoperative pain control (pain scores greater than 4 out of 10) after
              elective spinal surgery.
            </p>
          </div>
          <div className='section'>
            <h3 className='text-center'>Risk Calculator</h3>
            <div className='section-inner'>
              <Row justify='center'>
                <Col xl={16}>
                  <CAPPSForm calculateScore={this.calculateScore} />
                </Col>
              </Row>
              <Row justify='center'>
                <Col xl={12}>
                  {loading || capps_score !== null ? (
                    <Card title='CAPPS Score' type='inner' loading={loading}>
                      <h4>{capps_score}</h4>
                      {capps_title}
                      <p>{capps_description}</p>
                    </Card>
                  ) : null}
                </Col>
              </Row>
            </div>
            <div className='section-inner'>
              <h3 className='text-center'>Background Information for the CAPPS Score</h3>
              <p>
                The CAPPS score is validated for adult patients (≥18 years) who is
                undergoing elective spine surgery (e.g., not emergent) and require
                hospital admission for at least 24-hours. This score should not be used
                for patients who are undergoing surgery for spinal fractures, who are
                planning to have two or more different operations on the same day, and
                patients who plan to be admitted to the intensive care unit after their
                spine surgery.
              </p>
              <p>
                Please consult with your physician/surgeon to help interpret this result.
                Pain treatment plans should be individualized to each patient.
              </p>
              <p>
                Please see the reference for additional details regarding the CAPPS score.
              </p>
              <br />
              <h5>Reference</h5>
              <a
                href='https://doi.org/10.3171/2020.5.SPINE20347'
                target='_blank'
                rel='noopener noreferrer'
              >
                Yang MM, Riva-Cambrin J, Cunningham J, et al. Development and validation
                of a clinical prediction score for poor postoperative pain control
                following elective spine surgery. Journal of Neurosurgery: Spine.
                2020;1(aop):1-10.
              </a>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}
