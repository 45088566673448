import React, { Fragment } from 'react'

import { MeetTheTeam } from 'src/components/MeetTheTeam'
import { AboutProgram } from 'src/components/home/AboutProgram'
import { Banner } from 'src/components/Banner'
import { PatientOverview } from 'src/components/PatientOverview'
import { ResearchGlance } from 'src/components/home/ResearchGlance'

export const Home = () => {
  return (
    <Fragment>
      <Banner />
      <PatientOverview />
      <ResearchGlance />
      <MeetTheTeam />
      <AboutProgram />
    </Fragment>
  )
}
