import React, { Fragment, useEffect, useState } from 'react'
import { Col, Row } from 'antd'
import dayjs from 'dayjs'

export const Careers = () => {
  const [showJob, setShowJob] = useState(false)

  // useEffect(() => {
  //   const today = dayjs()

  //   if (today.isBefore('2024-09-03')) setShowJob(true)
  // }, [])

  return (
    <Fragment>
      <div className='container page'>
        <h1 className='text-center'>Careers</h1>
        <div className='text-center'>
          Join an exceptional team dedicated to the constant pursuit of excellence in
          research, teaching, and clinical care. We're committed to attracting the most
          talented, skilled team members, and providing them with the environment to
          succeed.
        </div>
        <div className='section-inner'>
          {showJob ? (
            <div>
              <h4 className='muted'>Job Postings</h4>
              <Row align='top' gutter={[16, 16]} style={{ marginBottom: '64px' }}>
                <Col xl={6}>
                  <div>
                    <h4>Clinical Research Coordinator &rarr;</h4>
                  </div>
                </Col>
                <Col xl={18}>
                  <div className='text-left'>
                    <p>
                      The University of Calgary Spine Surgery Program is a
                      Neurosurgical/Orthopaedic Surgery integrated program active in basic
                      science and clinical research, fellow/resident/medical student
                      education and spine clinical care delivery planning. Currently we
                      are seeking a highly skilled and efficient Clinical Research
                      Coordinator to support our research.
                    </p>
                    <p>
                      This position requires multi-site work with time spent at the
                      University of Calgary Foothills Campus and Caleo Health. Workdays
                      may vary in length and may occasionally include early morning,
                      evening or weekend hours depending on specific research protocols.
                      This is an externally-funded (non U of C) 1.0 FTE contractor
                      position, expected to last six months with an intention to consider
                      annual renewal thereafter.
                    </p>
                    <p>
                      This position requires a high level of technical expertise including
                      proficiency with MS Office suite. There will be periods of high work
                      volume with strict deadlines as required for the preparation and
                      collection of data sheets for patient visits, interactions with
                      patients, and maintenance of study databases.
                    </p>
                    <p>
                      Additionally, this person will be responsible for and participate in
                      the management of one or more of the following aspects of research:
                      recruitment and enrolment of human subjects; protection of subjects
                      and subjects’ rights according to the International Conference on
                      Harmonization (ICH), Declaration of Helsinki guidelines, the
                      Tri‐council Policy Statement and through Conjoint Health Research
                      Ethics Board (CHREB) requirements, data collection and audits of
                      accuracy.
                    </p>
                    <p>Projected start date October 2024</p>
                    <br />
                    <h6 style={{ marginTop: '1rem' }}>
                      Qualifications/Expertise Required:
                    </h6>
                    <ul>
                      <li>Bachelor’s degree in science required</li>
                      <li>
                        Certified Clinical Research Professional (CCRP) certification is
                        an asset
                      </li>
                      <li>Prior clinical research is preferred but not required</li>
                      <li>
                        A combination of education and experience will be considered
                      </li>
                      <li>
                        Highly developed organizational skills with proven ability to work
                        independently and collaboratively
                      </li>
                      <li>
                        Strong written and verbal communication skills are a necessity{' '}
                      </li>
                      <li>Strong interpersonal and teamwork skills are required</li>
                      <li>
                        Proficiency with computer software for writing and presentations,
                        databases, database design, and electronic medical record programs
                      </li>
                      <li>
                        Intermediate to advanced skills in Microsoft Office Suite (Word,
                        Excel, PowerPoint).
                      </li>
                    </ul>
                    <br />
                    <h6 style={{ marginTop: '1rem' }}>Key Responsibilities:</h6>
                    <ul>
                      <li>
                        Screening and enrolling patients into research trials, and
                        ensuring appropriate follow up for research studies.
                      </li>
                      <li>
                        Data collection and data entry for research projects. Data
                        collection may include patient data acquired through
                        questionnaires and/or chart reviews.
                      </li>
                      <li>
                        Organizing time to ensure work is completed and important projects
                        are on track (establish priorities).
                      </li>
                      <li>
                        Proactive anticipation of problems and development of preventative
                        solutions.
                      </li>
                      <li>
                        Performing radiographic measurements under the supervision of the
                        principal investigator(s).
                      </li>
                      <li>Attending the monthly spine department research meeting.</li>
                      <li>
                        Reporting to the research manager on progress and status of
                        projects.
                      </li>
                    </ul>
                    <br />
                    <h6 style={{ marginTop: '1rem' }}>How to Apply:</h6>
                    <br />
                    Interested candidates are invited to submit their letter of interest,
                    your CV and 2 letters of references to Dr. Nathan Evaniew
                    (nathan.evaniew@ucalgary.ca) before September 1st, 2024.
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <h4 className='muted text-center'>No Current Job Openings</h4>
          )}
        </div>
      </div>
    </Fragment>
  )
}
