import React, { Fragment } from 'react'

import { PatientOverview } from '../components/PatientOverview'

export const PatientPathway = () => {
  return (
    <Fragment>
      <div className='page'>
        <PatientOverview />
      </div>
    </Fragment>
  )
}
