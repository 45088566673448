import React, { Fragment } from 'react'

import { ComingSoon } from '../components/ComingSoon'

export const PatientAssessment = () => {
  return (
    <Fragment>
      <div className='container page'>
        <ComingSoon
          title='Patient Assessment'
          description='Stay tuned. We are working to bring you an online assessment experience.'
        />
      </div>
    </Fragment>
  )
}
