import React, { useState } from 'react'
import { Row, Tabs, Col } from 'antd'

import { TeamMember } from './TeamMember'

export const MeetTheTeam = () => {
  const [activeTab, setActiveTab] = useState('leadership')

  return (
    <div className='container section'>
      <h1 className='text-center'>Meet the Team</h1>
      <div className='section-inner'>
        <Tabs
          activeKey={activeTab}
          onChange={(tab) => setActiveTab(tab)}
          centered
          tabBarStyle={{ marginBottom: '3rem' }}
        >
          <Tabs.TabPane tab='Leadership' key='leadership' forceRender>
            <Row justify='center' gutter={[32, 32]}>
              <Col span={20}>
                <Row justify='center' gutter={[32, 32]}>
                  <TeamMember
                    fileName='jacobs'
                    name='Dr. W. Bradley Jacobs'
                    credentials='Chairman'
                    link='/about/message'
                  />
                  <TeamMember
                    fileName='evaniew'
                    name='Dr. Nathan Evaniew'
                    credentials='Research Director'
                    link='/research/roadmap'
                  />
                  <TeamMember
                    fileName='nicholls'
                    name='Dr. Fred Nicholls'
                    credentials='Fellowhip Director'
                    link='/fellowship/message'
                  />
                </Row>
              </Col>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab='Surgeons' key='surgeons' forceRender>
            <Row justify='center' gutter={[32, 32]}>
              <Col span={20}>
                <Row justify='center' gutter={[32, 32]}>
                  <TeamMember
                    fileName='bouchard'
                    name='Dr. Jacques Bouchard'
                    credentials='MD FRCSC'
                    location='Orthopedic Spine Surgery'
                  />
                  <TeamMember
                    fileName='cadotte'
                    name='Dr. David W. Cadotte'
                    credentials='MD PhD FRCSC'
                    location='Neurological Spine Surgery'
                  />
                  <TeamMember
                    fileName='casha'
                    name='Dr. Steve Casha'
                    credentials='MD PhD FRCSC'
                    location='Neurological Spine Surgery'
                  />
                  <TeamMember
                    fileName='cho'
                    name='Dr. Roger Cho'
                    credentials='MD FRCSC'
                    location='Orthopedic Spine Surgery'
                  />
                  <TeamMember
                    fileName='duplessis'
                    name='Dr. Stephan du Plessis'
                    credentials='MD FRCSC'
                    location='Neurological Spine Surgery'
                  />
                  <TeamMember
                    fileName='evaniew'
                    name='Dr. Nathan Evaniew'
                    credentials='MD PhD FRCSC'
                    location='Orthopedic Spine Surgery'
                  />
                  <TeamMember
                    fileName='hu'
                    name='Dr. Rick Hu'
                    credentials='MD FRCSC'
                    location='Orthopedic Spine Surgery'
                  />
                  <TeamMember
                    fileName='jacobs'
                    name='Dr. W. Bradley Jacobs'
                    credentials='MD FRCSC'
                    location='Neurological Spine Surgery'
                  />
                  <TeamMember
                    fileName='lewkonia'
                    name='Dr. Peter Lewkonia'
                    credentials='MD MSc FRCSC'
                    location='Orthopedic Spine Surgery'
                  />
                  <TeamMember
                    fileName='nicholls'
                    name='Dr. Fred Nicholls'
                    credentials='MD MA FRCSC'
                    location='Orthopedic Spine Surgery'
                  />
                  <TeamMember
                    fileName='parsons'
                    name='Dr. David Parsons'
                    credentials='MD FRCSC'
                    location='Pediatric Orthopedic Spine Surgery'
                  />
                  <TeamMember
                    fileName='salo'
                    name='Dr. Paul Salo'
                    credentials='MD FRCSC'
                    location='Orthopedic Spine Surgery'
                  />
                  <TeamMember
                    fileName='soroceanu'
                    name='Dr. Alex Soroceanu'
                    credentials='MD CM MPH FRCSC'
                    location='Orthopedic Spine Surgery'
                  />
                  <TeamMember
                    fileName='swamy'
                    name='Dr. Ganesh Swamy'
                    credentials='MD PhD FRCSC'
                    location='Orthopedic Spine Surgery'
                  />
                  <TeamMember
                    fileName='thomas'
                    name='Dr. Ken Thomas'
                    credentials='MD MHSc FRCSC'
                    location='Orthopedic Spine Surgery'
                  />
                  <TeamMember
                    fileName='m_yang'
                    name='Dr. Michael Yang'
                    credentials='MD MSc M.Biotech FRCSC'
                    location='Neurological Spine Surgery'
                  />
                </Row>
              </Col>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab='Specialized Team' key='specialized' forceRender>
            <Row justify='center' gutter={[32, 32]}>
              <Col span={20}>
                <Row justify='center' gutter={[32, 32]}>
                  <TeamMember
                    fileName='bennitz'
                    name='Dr. Joshua Bennitz'
                    credentials='MD FRCPC'
                    location='Complex Spine Anesthesia Group'
                  />
                  <TeamMember
                    fileName='rogan'
                    name='Dr. Kyle Rogan'
                    credentials='MD FRCPC'
                    location='Complex Spine Anesthesia Group'
                  />
                  <TeamMember
                    fileName='moghadam'
                    name='Ken Moghadam'
                    credentials='Certified Orthotist'
                    location='Cascade Orthotics'
                  />
                  <TeamMember
                    fileName='wright'
                    name='Jeff Wright'
                    credentials='Certified Orthotist'
                    location='Cascade Orthotics'
                  />
                  <TeamMember
                    fileName='cameron'
                    name='Rob Cameron'
                    credentials='Certified Orthotist'
                    location='Cascade Orthotics'
                  />
                  <TeamMember
                    fileName='anholt'
                    name='Drew Anholt'
                    credentials='Certified Orthotist'
                    location='Cascade Orthotics'
                  />
                  <TeamMember
                    fileName='whittaker'
                    name='Tara Whittaker'
                    credentials='RN BN CCRC & Photographer'
                    location='Research Team Manager'
                  />
                  <TeamMember
                    fileName='tripathy'
                    name='Saswati Tripathy'
                    credentials='RA PhD'
                    location='Research Team'
                  />
                  <TeamMember
                    fileName='smith'
                    name='Vicki Smith'
                    credentials='PhD'
                    location='Research Team'
                  />
                  <TeamMember
                    fileName='dean'
                    name='Carla Dean'
                    credentials='Nurse Practitioner'
                    location='Orthopedic Spine Surgery'
                  />
                  <TeamMember
                    fileName='prince'
                    name='Ron Prince'
                    credentials='Nurse Practitioner'
                    location='Neurological Spine Surgery'
                  />
                  <TeamMember
                    fileName='miller'
                    name='Nicole Miller'
                    credentials='Nurse Practitioner'
                    location='Orthopedic Spine Surgery'
                  />
                  <TeamMember
                    fileName='fischer'
                    name='Erin Fischer'
                    credentials='Nurse Clinician'
                    location='Operating Room'
                  />
                  <TeamMember
                    fileName='marsh'
                    name='Chris Marsh'
                    credentials='Nurse Clinician'
                    location='Operating Room'
                  />
                  <TeamMember
                    fileName='masson'
                    name='Anand Oliveira Masson'
                    credentials='PhD'
                    location='Research Team'
                  />
                  <TeamMember
                    fileName='derevyansky'
                    name='Michelle Derevyansky'
                    credentials='Coordinator'
                    location='Research Team'
                  />
                </Row>
              </Col>
            </Row>
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  )
}
