import React, { Fragment } from 'react'
import Slider from 'react-slick'
import { Row, Col } from 'antd'

export const Testimonials = () => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1500,
    autoplaySpeed: 10000,
    fade: true,
  }

  return (
    <Fragment>
      <div className='container page'>
        <h1 className='text-center'>Fellowship Testimonials</h1>
        <div className='section-inner'>
          <Slider {...settings}>
            <div>
              <Row justify='center' gutter={32}>
                <Col xl={8}>
                  <picture>
                    <source srcSet={'/images/team/kassam.webp'} type='image/webp' />
                    <img
                      srcSet={'/images/team/kassam.jpg'}
                      alt='portrait'
                      className='testimonial-img'
                    />
                  </picture>
                </Col>
                <Col xl={14} style={{ padding: '1rem' }}>
                  <h4>Dr. Faizal Kassam, 2019-2020</h4>
                  <h5>Queen's University, Canada</h5>
                  <br />
                  <p>
                    When I showed up to Calgary in July 2020, I was decent with a mallet
                    but barely knew how to hold a Kerrison. I looked down, looked up
                    again, and suddenly it was March 2021. I was doing anterior cervical
                    discectomies with a resident assisting.
                  </p>
                  <p>
                    From the entertainment of Spine Rounds to the delicious offerings at
                    monthly journal clubs, the year flew by. Through the hard work and
                    patience of my mentors in Calgary, I became technically proficient in
                    anterior, lateral and posterior-based procedures. I will always be
                    thankful to my co-Fellows, the Residents that I worked with, and the
                    Attendings who provided me with unlimited opportunities to grow. I
                    think of them every single day.
                  </p>
                  <p>
                    For anyone lucky enough to be admitted to this fellowship, my advice
                    would be to take it before the opportunity is gone. You won’t regret
                    it. I am currently an attending spine & trauma surgeon at Queen’s
                    University and will always be proud to say that I’m a Calgary-trained
                    spine surgeon.
                  </p>
                </Col>
              </Row>
            </div>
            <div>
              <Row justify='center' gutter={32}>
                <Col xl={8}>
                  <picture>
                    <source srcSet={'/images/team/aoude.webp'} type='image/webp' />
                    <img
                      srcSet={'/images/team/aoude.jpg'}
                      alt='portrait'
                      className='testimonial-img'
                    />
                  </picture>
                </Col>
                <Col xl={14} style={{ padding: '1rem' }}>
                  <h4>Dr. Ahmed Aoude, 2018-2019</h4>
                  <h5>McGill University, Canada</h5>
                  <br />
                  <p>
                    I remember receiving my acceptance to complete a Fellowship in Spine
                    at University of Calgary very vividly. I was at he same time
                    considering Harvard, Mayo Clinic & John Hopkins. I was very excited,
                    but i have to admit, not too sure about the city and program. I then
                    decided to find out more and talk to previous Fellows. Their unanimous
                    high appraisal of the program was enough to convince me to join this
                    wonderful team. I am very grateful to have made the right decision
                    every day.
                  </p>
                  <p>
                    After this fellowship I feel confident to attack any spine cases
                    whether small or big. This I owe to all my mentors. I now am
                    confortable to do MIS cases, lateral approaches, anterior approaches,
                    tumor, trauma, degenerative spine, cervical cases and deformity. The
                    team at University of Calgary were all great teachers but also great
                    role models for all Fellows when it came to patient care. I also
                    greatly appreciate the respect and kindness of all operating room
                    staff during my time there.
                  </p>
                  <p>
                    All these factors make the Spine Fellowship at University of Calgary
                    so highly regarded. I am currently completing a fellowship in
                    orthopaedic oncology in Toronto and find myself doing complex cases
                    daily but feel competent after all the surgucal experience and complex
                    cases I completed in Calgary.
                  </p>
                  <p>
                    I want to thank all my fellowship supervisors for the exceptional
                    mentorship, teaching and most importantly, friendships I have built.
                    The reputation of the University of Calgary Spine Program as being the
                    best program in North America is true and warranted.
                  </p>
                  <p>
                    PS... It's not even been a year and I miss Calgary... I miss the
                    beauty and peacefulness of the city but also my mentors and colleagues
                    inside and outside the operating room.
                  </p>
                </Col>
              </Row>
            </div>
            <div>
              <Row justify='center' gutter={32}>
                <Col xl={8}>
                  <picture>
                    <source srcSet={'/images/team/fox.webp'} type='image/webp' />
                    <img
                      srcSet={'/images/team/fox.jpg'}
                      alt='portrait'
                      className='testimonial-img'
                    />
                  </picture>
                </Col>
                <Col xl={14} style={{ padding: '1rem' }}>
                  <h4>Dr. Shandy Fox, 2016-2017</h4>
                  <h5>University of Saskatchewan, Canada</h5>
                  <br />
                  <p>
                    The Calgary Adult Combined Spine Surgery Fellowship offers an amazing
                    educational experience that will prepare you for a successful career
                    in spinal surgery. First and foremost, the program is truly an example
                    of what an integrated program should be. Both specialties are equally
                    represented and the staff work very well together, emulating a
                    positive and team-oriented environment. Secondly, it is a unique
                    experience to work with so many skilled surgeons. As each of them have
                    varying interests and approaches, the learner gains a range of skills
                    and knowledge to add to their “toolbox”. The case volume is
                    substantial with exposure to a wide variety of pathologies, from
                    simple to complex. The program also embraces evidence-based medicine,
                    new technology and new techniques that further advance the learning
                    experience. I was very fortunate to learn from and work alongside such
                    a great group and I highly recommend this fellowship!
                  </p>
                </Col>
              </Row>
            </div>
            <div>
              <Row justify='center' gutter={32}>
                <Col xl={8}>
                  <picture>
                    <source srcSet={'/images/team/stratton.webp'} type='image/webp' />
                    <img
                      srcSet={'/images/team/stratton.jpg'}
                      alt='portrait'
                      className='testimonial-img'
                    />
                  </picture>
                </Col>
                <Col xl={14} style={{ padding: '1rem' }}>
                  <h4>Dr. Alexandra Stratton, 2015-2016</h4>
                  <h5>Memorial University of Newfoundland, Canada</h5>
                  <br />
                  <p>
                    The Calgary Combined Spinal Surgery Fellowship provides a phenomenal
                    training experience. Pros include: the 6 month/6 month split between
                    ortho and neurosurgery giving adequate exposure to both, exceptional
                    technical skill of the surgeons and their interest in teaching,
                    variety of procedures the fellow is exposed to (from routine lumbar
                    decompressions and ACDFs to complex deformity corrections and tumour
                    cases) and approaches (MIS/percutaneous, open, with/without
                    navigation, etc.), graduated responsibility depending on fellow’s
                    level of experience, high volume of cases and structured teaching
                    program. Administrative duties/scut work is kept to a minimum and the
                    ratio between OR, clinic and research is ideal. There is ample
                    opportunity for early involvement in research projects with the goal
                    of presenting at CSS in February. This fellowship prepares the fellow
                    well for a career in complex spinal surgery in an academic centre.
                  </p>
                </Col>
              </Row>
            </div>
            <div>
              <Row justify='center' gutter={32}>
                <Col xl={8}>
                  <picture>
                    <source srcSet={'/images/team/oh.webp'} type='image/webp' />
                    <img
                      srcSet={'/images/team/oh.jpg'}
                      alt='portrait'
                      className='testimonial-img'
                    />
                  </picture>
                </Col>
                <Col xl={14} style={{ padding: '1rem' }}>
                  <h4>Dr. Jacob Oh, 2013-2014</h4>
                  <h5>National University of Singapore, Singapore</h5>
                  <br />
                  <p>
                    Greetings from Singapore! I am an Orthopedic Spine Consultant and I
                    had the privilege to undertake my Combined Spine Surgery Fellowship at
                    the University of Calgary in 2013/14. I had a fantastic time working
                    with 11 of the most dedicated, skillful and approachable group of
                    spine surgeons. With only 4 spine fellows, we had a substantial amount
                    of surgical time and volume, which is what most candidates desire in
                    their fellowship training. With Foothills Medical Centre having such a
                    big spine department, I had the opportunity to see, and perform, a
                    large variety of cases – from adult deformity, minimally invasive
                    surgery, complex cervical spine, tumor, acute trauma, the list goes
                    on. Also, what’s unique about this Combined Spine Surgery Fellowship
                    program is that we get to rotate through both the Neurosurgical and
                    Orthopedics teams, giving us a different perspective, which I
                    personally feel is invaluable. I would definitely recommend this
                    fellowship to anybody who is keen in spine surgery!
                  </p>
                </Col>
              </Row>
            </div>
          </Slider>
        </div>
      </div>
    </Fragment>
  )
}
