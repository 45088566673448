import React from 'react'
import { Row, Col, Button, Space } from 'antd'
import Slider from 'react-slick'

export const ResearchGlance = () => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
  }

  return (
    <div className='section section-dark'>
      <div className='container'>
        <Row align='middle'>
          <Col xl={4}>
            <picture>
              <source srcSet={'/images/spine.webp'} type='image/webp' />
              <img
                src={'/images/spine.png'}
                className='spine-img-small mobile-hidden'
                alt='spine'
              />
            </picture>
          </Col>
          <Col xl={20}>
            <Slider {...settings}>
              <div>
                <h1 style={{ color: '#fff' }}>Scientific Registry</h1>
                <h4 className='muted'>
                  The University of Calgary Spine Program is designing a Scientific
                  Registry for the study of spinal disorders. Registered patients will be
                  able to provide their data to help answer patient-centered questions.
                </h4>
                <div style={{ marginTop: '4rem' }}>
                  <Space>
                    <Button
                      shape='round'
                      size='large'
                      href='/pdf/whitepaper_may2020.pdf'
                      target='_blank'
                    >
                      Download Whitepaper
                    </Button>
                    <Button shape='round' size='large' href='/research/roadmap'>
                      View Roadmap
                    </Button>
                  </Space>
                </div>
              </div>
              <div>
                <h1 style={{ color: '#fff' }}>Newsletter</h1>
                <h4 className='muted'>APRIL 22, 2024, VOL. 1, NO. 8</h4>
                <div style={{ marginTop: '4rem' }}>
                  <Button
                    shape='round'
                    size='large'
                    style={{ margin: '0.5rem' }}
                    href='/pdf/newsletter_apr2024.pdf'
                    target='_blank'
                  >
                    Download Newsletter
                  </Button>
                  <Button
                    shape='round'
                    size='large'
                    style={{ margin: '0.5rem' }}
                    href='/about/documents'
                  >
                    View All
                  </Button>
                </div>
              </div>
              <div>
                <h1 style={{ color: '#fff' }}>Annual Report</h1>
                <h4 className='muted'>2021 - 2022</h4>
                <div style={{ marginTop: '4rem' }}>
                  <Button
                    shape='round'
                    size='large'
                    style={{ margin: '0.5rem' }}
                    href='/pdf/annual_report_2021_2022.pdf'
                    target='_blank'
                  >
                    Download Report
                  </Button>
                  <Button
                    shape='round'
                    size='large'
                    style={{ margin: '0.5rem' }}
                    href='/about/documents'
                  >
                    View All
                  </Button>
                </div>
              </div>
            </Slider>
          </Col>
        </Row>
      </div>
    </div>
  )
}
