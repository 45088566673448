import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import { Col, Row, Drawer } from 'antd'
import { MenuOutlined } from '@ant-design/icons'

export const MobileHeader = ({ path = '' }) => {
  const [showDrawer, setShowDrawer] = useState(false)

  return (
    <Fragment>
      <Row align='middle'>
        <Col xl={6} md={6} sm={12} xs={12}>
          <Link to='/'>
            <img src='/images/spine-program-logo.png' className='logo-img' alt='uofc' />
          </Link>
        </Col>
        <Col xl={18} md={18} sm={12} xs={12}>
          <Row justify='end'>
            <Col>
              <MenuOutlined
                style={{ cursor: 'pointer' }}
                onClick={() => setShowDrawer(true)}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Drawer
        visible={showDrawer}
        onClose={() => setShowDrawer(false)}
        placement='right'
        title='Navigation'
      >
        <span className={path.includes('/patient') ? 'btn-header-active' : 'btn-header'}>
          Patients
        </span>
        <ul className='header-footer-list'>
          <li>
            <Link className='btn-footer' to='/patient/assessment'>
              Take Assessment
            </Link>
          </li>
          <li>
            <Link className='btn-footer' to='/patient/pathway'>
              Patient Pathway
            </Link>
          </li>
          <li>
            <Link className='btn-footer' to='/about/contact'>
              Find a Clinic
            </Link>
          </li>
        </ul>
        <span className={path.includes('/research') ? 'btn-header-active' : 'btn-header'}>
          Research
        </span>
        <ul className='header-footer-list'>
          <li>
            <Link className='btn-footer' to='/research/roadmap'>
              Roadmap
            </Link>
          </li>
          <li>
            <Link className='btn-footer' to='/research/capps'>
              Pain After Spinal Surgery
            </Link>
          </li>
        </ul>
        <span
          className={path.includes('/fellowship') ? 'btn-header-active' : 'btn-header'}
        >
          Fellowship
        </span>
        <ul className='header-footer-list'>
          <li>
            <Link className='btn-footer' to='/fellowship/message'>
              A Message from the Director
            </Link>
          </li>
          <li>
            <Link className='btn-footer' to='/fellowship/past-fellows'>
              Past Fellows
            </Link>
          </li>
          <li>
            <Link className='btn-footer' to='/fellowship/testimonials'>
              Testimonials
            </Link>
          </li>
          <li>
            <Link className='btn-footer' to='/fellowship/apply'>
              Apply
            </Link>
          </li>
        </ul>
        <span className={path.includes('/about') ? 'btn-header-active' : 'btn-header'}>
          About
        </span>
        <ul className='header-footer-list'>
          <li>
            <Link className='btn-footer' to='/about/message'>
              A Message from the Chairman
            </Link>
          </li>
          <li>
            <Link className='btn-footer' to='/about/team'>
              Meet the Team
            </Link>
          </li>
          <li>
            <Link className='btn-footer' to='/about/contact'>
              Contact
            </Link>
          </li>
          <li>
            <Link className='btn-footer' to='/about/careers'>
              Careers
            </Link>
          </li>
          <li>
            <Link className='btn-footer' to='/about/documents'>
              Documents
            </Link>
          </li>
        </ul>
        <span className='btn-header'>Account</span>
        <ul className='header-footer-list'>
          <li>
            <Link className='btn-footer' to='/login'>
              Log In
            </Link>
          </li>
          <li>
            <Link className='btn-footer' to='/signup'>
              Sign Up
            </Link>
          </li>
        </ul>
      </Drawer>
    </Fragment>
  )
}
