import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Row, Dropdown, Menu } from 'antd'
import { TwitterOutlined } from '@ant-design/icons'

export const DesktopHeader = ({ path = '' }) => {
  const patientMenu = (
    <Menu>
      <Menu.Item>
        <Link to='/patient/assessment'>Take Assessment</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to='/patient/pathway'>Patient Pathway</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to='/about/contact'>Find a Clinic</Link>
      </Menu.Item>
    </Menu>
  )

  const researchMenu = (
    <Menu>
      <Menu.Item>
        <Link to='/research/roadmap'>Roadmap</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to='/research/capps'>Pain After Spinal Surgery</Link>
      </Menu.Item>
    </Menu>
  )

  const fellowshipMenu = (
    <Menu>
      <Menu.Item>
        <Link to='/fellowship/message'>A Message from the Director</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to='/fellowship/past-fellows'>Past Fellows</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to='/fellowship/testimonials'>Testimonials</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to='/fellowship/apply'>Apply</Link>
      </Menu.Item>
    </Menu>
  )

  const aboutMenu = (
    <Menu>
      <Menu.Item>
        <Link to='/about/message'>A Message from the Chairman</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to='/about/team'>Meet the Team</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to='/about/contact'>Contact</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to='/about/careers'>Careers</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to='/about/documents'>Documents</Link>
      </Menu.Item>
    </Menu>
  )

  return (
    <Row align='middle'>
      <Col xl={6} md={6} sm={24}>
        <Link to='/'>
          <picture>
            <source srcSet={'/images/spine-program-logo.webp'} type='image/webp' />
            <img
              src='/images/spine-program-logo.png'
              className='logo-img'
              alt='spine-program'
            />
          </picture>
        </Link>
      </Col>
      <Col xl={18} md={18} sm={24}>
        <Row justify='end' align='middle' gutter={32}>
          <Col>
            <Dropdown arrow overlay={patientMenu}>
              <span
                className={path.includes('/patient') ? 'btn-header-active' : 'btn-header'}
              >
                Patients
              </span>
            </Dropdown>
          </Col>
          <Col>
            <Dropdown arrow overlay={researchMenu}>
              <span
                className={
                  path.includes('/research') ? 'btn-header-active' : 'btn-header'
                }
              >
                Research
              </span>
            </Dropdown>
          </Col>
          <Col>
            <Dropdown arrow overlay={fellowshipMenu}>
              <span
                className={
                  path.includes('/fellowship') ? 'btn-header-active' : 'btn-header'
                }
              >
                Fellowship
              </span>
            </Dropdown>
          </Col>
          <Col>
            <Dropdown arrow overlay={aboutMenu}>
              <span
                className={path.includes('/about') ? 'btn-header-active' : 'btn-header'}
              >
                About
              </span>
            </Dropdown>
          </Col>
          <Col>
            <Link to='/login' className='btn-header'>
              Log In
            </Link>
          </Col>
          <Col>
            <a
              className='btn-header'
              href='https://twitter.com/CalgarySpine'
              target='_blank'
              rel='noopener noreferrer'
            >
              <TwitterOutlined />
            </a>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
