import React, { Fragment } from 'react'
import { Row, Col } from 'antd'

export const Contact = () => {
  return (
    <Fragment>
      <div className='container page'>
        <h1 className='text-center'>Find a Clinic</h1>
        <div className='section-inner'>
          <Row align='middle' gutter={[32, 32]} style={{ marginBottom: '64px' }}>
            <Col xl={12}>
              <picture>
                <source srcSet={'/images/clinic-1.webp'} type='image/webp' />
                <img
                  srcSet={'/images/clinic-1.jpg'}
                  alt='clinic1'
                  className='clinic-img mobile-hidden'
                />
              </picture>
            </Col>
            <Col xl={12}>
              <div className='text-center'>
                <h3>Spinal Triage and Assessment Clinic</h3>
                <h4 className='muted'>Foothills Medical Center</h4>
                <h4 className='muted'>1409 29 Street NW</h4>
                <h4 className='muted'>Calgary, Alberta T2N 2T9</h4>
                <br />
                <h6>Phone: 403-944-5426</h6>
                <h6>Fax: 403-283-2270</h6>
              </div>
            </Col>
          </Row>
          <Row align='middle' gutter={[32, 32]}>
            <Col xl={12}>
              <picture>
                <source srcSet={'/images/clinic-2.webp'} type='image/webp' />
                <img
                  srcSet={'/images/clinic-2.jpg'}
                  alt='clinic1'
                  className='clinic-img mobile-hidden'
                />
              </picture>
            </Col>
            <Col xl={12}>
              <div className='text-center'>
                <h3>Caleo Health Spine Triage Clinic</h3>
                <h4 className='muted'>
                  200 - 1402 8<sup>th</sup> Avenue NW
                </h4>
                <h4 className='muted'>Calgary, Alberta T2N 1B9</h4>
                <br />
                <h6>Phone: 403-452-0999</h6>
                <h6>Fax: 403-452-0995</h6>
                <br />
                <a
                  href='https://caleohealth.ca/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Visit their website
                </a>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Fragment>
  )
}
