import React from 'react'
import { Row, Col, Button } from 'antd'
import { useNavigate } from 'react-router-dom'

export const PatientOverview = () => {
  const navigate = useNavigate()

  return (
    <div className='section'>
      <h1 className='text-center'>Patient Pathway</h1>
      <Row justify='center' align='middle' className='section-inner'>
        <Col xl={14}>
          <picture>
            <source srcSet={'/images/pp.webp'} type='image/webp' />
            <img srcSet={'/images/pp.jpg'} alt='infographic' className='pp-img' />
          </picture>
        </Col>
      </Row>
      <div className='section-inner text-center'>
        <Button
          size='large'
          shape='round'
          onClick={() => navigate('/patient/assessment')}
        >
          Take Assessment
        </Button>
      </div>
    </div>
  )
}
