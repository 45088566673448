import React, { Fragment } from 'react'
import { Row, Col, Divider } from 'antd'

import { TeamMember } from 'src/components/TeamMember'

export const PastFellows = () => {
  return (
    <Fragment>
      <div className='container page'>
        <h1 className='text-center'>Past Fellows</h1>
        <div className='section-inner'>
          <Row justify='center' gutter={[32, 32]} style={{ marginBottom: '32px' }}>
            <Col span={20}>
              <Row justify='center' gutter={[32, 32]}>
                <TeamMember
                  fileName='pelletier-roy'
                  name='Dr. Remi Pelletier-Roy'
                  credentials='2023-2024'
                />
                <TeamMember
                  fileName='craig'
                  name='Dr. Michael Craig'
                  credentials='2023-2024'
                />
                <TeamMember
                  fileName='alDuwaisan'
                  name='Dr. Abdullah AlDuwaisan'
                  credentials='2023-2024'
                />
                <TeamMember
                  fileName='wood'
                  name='Dr. Addison Wood'
                  credentials='2022-2023'
                />
                <TeamMember
                  fileName='gagliardi'
                  name='Dr. Martin Gagliardi'
                  credentials='2022-2023'
                />
                <TeamMember
                  fileName='almojuela'
                  name='Dr. Alysa Almojuela'
                  credentials='2022-2023'
                />
                <TeamMember fileName='wu' name='Dr. James Wu' credentials='2022-2023' />
                <TeamMember
                  fileName='burger'
                  name='Dr. Lukas Burger'
                  credentials='2021-2022'
                />
                <TeamMember
                  fileName='aldahamsheh'
                  name='Dr. Osama Aldahamsheh'
                  credentials='2021-2022'
                />
                <TeamMember
                  fileName='coyle'
                  name='Dr. Matt Coyle'
                  credentials='2021-2022'
                />
                <TeamMember
                  fileName='cowley'
                  name='Dr. Richard Cowley'
                  credentials='2021-2022'
                />
                <TeamMember
                  fileName='rosa-filezio'
                  name='Dr. Marina Rosa Filezio (ACH)'
                  credentials='2020-2022'
                />
                <TeamMember
                  fileName='yang'
                  name='Dr. Victor Yang'
                  credentials='2020-2021'
                />
                <TeamMember
                  fileName='bogle'
                  name='Dr. Andrew Bogle'
                  credentials='2020-2021'
                />
                <TeamMember
                  fileName='kasmani'
                  name='Dr. Abdul Kasmani'
                  credentials='2020-2021'
                />
                <TeamMember
                  fileName='opperman'
                  name='Dr. Marcelo Opperman'
                  credentials='2020-2021'
                />
                <TeamMember
                  fileName='singh'
                  name='Dr. Vishwajeet Singh'
                  credentials='2020-2021'
                />
                <TeamMember
                  fileName='bond'
                  name='Dr. Michael Bond'
                  credentials='2019-2020'
                />
                <TeamMember
                  fileName='evaniew'
                  name='Dr. Nathan Evaniew'
                  credentials='2019-2020'
                />
                <TeamMember
                  fileName='mcgregor'
                  name='Dr. Stuart McGregor'
                  credentials='2019-2020'
                />
                <TeamMember
                  fileName='kassam'
                  name='Dr. Faizal Kassam'
                  credentials='2019-2020'
                />
                <TeamMember
                  fileName='aldebeyan'
                  name='Dr. Sultan Aldebeyan'
                  credentials='2018-2019'
                />
                <TeamMember
                  fileName='aoude'
                  name='Dr. Ahmed Aoude'
                  credentials='2018-2019'
                />
                <TeamMember
                  fileName='rogers'
                  name='Dr. Sasha Rogers'
                  credentials='2018-2019'
                />
                <TeamMember
                  fileName='sirois'
                  name='Dr. Patricia Sirois'
                  credentials='2017-2018'
                />
                <TeamMember
                  fileName='nares'
                  name='Dr. Felipe Nares'
                  credentials='2017-2018'
                />
                <TeamMember
                  fileName='zafeiris'
                  name='Dr. Christos Zafeiris'
                  credentials='2017-2018'
                />
                <TeamMember
                  fileName='cushnie'
                  name='Dr. Duncan Cushnie'
                  credentials='2017-2018'
                />
                <TeamMember
                  fileName='teles'
                  name='Dr. Alisson Roberto Teles'
                  credentials='2017-2018'
                />
                <TeamMember
                  fileName='cadotte'
                  name='Dr. David W. Cadotte'
                  credentials='2016-2017'
                />
                <TeamMember
                  fileName='manners'
                  name='Dr. Simon Manners'
                  credentials='2016-2017'
                />
                <TeamMember
                  fileName='fox'
                  name='Dr. Shandy Fox'
                  credentials='2016-2017'
                />
                <TeamMember
                  fileName='lyons'
                  name='Dr. Frank Lyons'
                  credentials='2016-2017'
                />
                <TeamMember
                  fileName='st_pierre'
                  name='Dr. Godefroy Hardy St-Pierre'
                  credentials='2015-2016'
                />
                <TeamMember
                  fileName='mansoori'
                  name='Dr. Khaled Al Mansoori'
                  credentials='2015-2016'
                />
                <TeamMember
                  fileName='fenton'
                  name='Dr. Eoin Fenton'
                  credentials='2015-2016'
                />
                <TeamMember
                  fileName='stratton'
                  name='Dr. Alexandra Stratton'
                  credentials='2015-2016'
                />
                <TeamMember fileName='yuh' name='Dr. Sung Yuh' credentials='2014-2015' />
                <TeamMember
                  fileName='avatar_placeholder'
                  name='Dr. Kanwaljeet Garg'
                  credentials='2014-2015'
                />
                <TeamMember
                  fileName='avatar_placeholder'
                  name='Dr. Antonio Tsahtsarlis'
                  credentials='2014-2015'
                />
                <TeamMember
                  fileName='avatar_placeholder'
                  name='Dr. Nathan Deis'
                  credentials='2014-2015'
                />
                <TeamMember
                  fileName='bouhouf'
                  name='Dr. Farid Bouhouf'
                  credentials='2013-2014'
                />
                <TeamMember
                  fileName='campbell'
                  name='Dr. Kate Campbell'
                  credentials='2013-2014'
                />
                <TeamMember
                  fileName='haugo'
                  name='Dr. Kristine Haugo'
                  credentials='2013-2014'
                />
                <TeamMember fileName='oh' name='Dr. Jacob Oh' credentials='2013-2014' />
              </Row>
            </Col>
          </Row>
          <Row justify='center' gutter={[32, 32]} style={{ marginBottom: '32px' }}>
            <Col xl={12} className='text-center'>
              <h3>2012-2013</h3>
              <Divider />
              <div>
                <h6>Dr. Michael Spiess</h6>
              </div>
              <br />
              <div>
                <h6>Dr. Sean Suttor</h6>
              </div>
              <br />
              <div>
                <h6>Dr. Rohit Amritanand</h6>
              </div>
              <br />
              <div>
                <h6>Dr. Saurabh Rawall</h6>
              </div>
            </Col>
          </Row>
          <Row justify='center' gutter={[32, 32]} style={{ marginBottom: '32px' }}>
            <Col xl={12} className='text-center'>
              <h3>2011-2012</h3>
              <Divider />
              <div>
                <h6>Dr. Shadi Shahata</h6>
              </div>
              <br />
              <div>
                <h6>Dr. Christina Goldstein</h6>
              </div>
              <br />
              <div>
                <h6>Dr. Eric Huang</h6>
              </div>
              <br />
              <div>
                <h6>Dr. Hani Alsulaimany</h6>
              </div>
            </Col>
          </Row>
          <Row justify='center' gutter={[32, 32]} style={{ marginBottom: '32px' }}>
            <Col xl={12} className='text-center'>
              <h3>2010-2011</h3>
              <Divider />
              <div>
                <h6>Dr. Jonathon Parkinson</h6>
              </div>
              <br />
              <div>
                <h6>Dr. Charbel Fawaz</h6>
              </div>
              <br />
              <div>
                <h6>Dr. Sachin Mahajan</h6>
              </div>
              <br />
              <div>
                <h6>Dr. Shadi Shahata</h6>
              </div>
            </Col>
          </Row>
          <Row justify='center' gutter={[32, 32]} style={{ marginBottom: '32px' }}>
            <Col xl={12} className='text-center'>
              <h3>2009-2010</h3>
              <Divider />
              <div>
                <h6>Dr. John Cunningham</h6>
              </div>
              <br />
              <div>
                <h6>Dr. Jacob Alant</h6>
              </div>
              <br />
              <div>
                <h6>Dr. Alexandre Denault</h6>
              </div>
              <br />
              <div>
                <h6>Dr. Mark Winder</h6>
              </div>
            </Col>
          </Row>
          <Row justify='center' gutter={[32, 32]} style={{ marginBottom: '32px' }}>
            <Col xl={12} className='text-center'>
              <h3>2008-2009</h3>
              <Divider />
              <div>
                <h6>Dr. Geberth Urbaneja</h6>
              </div>
              <br />
              <div>
                <h6>Dr. Jonathon Ball</h6>
              </div>
              <br />
              <div>
                <h6>Dr. Aleksa Cenic</h6>
              </div>
              <br />
              <div>
                <h6>Dr. Sohail Bajammal</h6>
              </div>
              <div>
                <h6>Dr. Raoul Pope</h6>
              </div>
            </Col>
          </Row>
          <Row justify='center' gutter={[32, 32]} style={{ marginBottom: '32px' }}>
            <Col xl={12} className='text-center'>
              <h3>2007-2008</h3>
              <Divider />
              <div>
                <h6>Dr. Amro Al-Habib</h6>
              </div>
              <br />
              <div>
                <h6>Dr. Anwar Al-Rabiah</h6>
              </div>
              <br />
              <div>
                <h6>Dr. Najmedden Attabib</h6>
              </div>
            </Col>
          </Row>
          <Row justify='center' gutter={[32, 32]} style={{ marginBottom: '32px' }}>
            <Col xl={12} className='text-center'>
              <h3>2006-2007</h3>
              <Divider />
              <div>
                <h6>Dr. Jocelyn Blanchard</h6>
              </div>
              <br />
              <div>
                <h6>Dr. Mohammed Halawani</h6>
              </div>
              <br />
              <div>
                <h6>Dr. Hui Li</h6>
              </div>
              <br />
              <div>
                <h6>Dr. Sami Al-Eissa</h6>
              </div>
            </Col>
          </Row>
          <Row justify='center' gutter={[32, 32]} style={{ marginBottom: '32px' }}>
            <Col xl={12} className='text-center'>
              <h3>2005-2006</h3>
              <Divider />
              <div>
                <h6>Dr. Paolo Punsalan</h6>
              </div>
              <br />
              <div>
                <h6>Dr. Jerome Paquette</h6>
              </div>
              <br />
              <div>
                <h6>Dr. Jingcheng Xie</h6>
              </div>
              <br />
              <div>
                <h6>Dr. Sean Christie</h6>
              </div>
            </Col>
          </Row>
          <Row justify='center' gutter={[32, 32]} style={{ marginBottom: '32px' }}>
            <Col xl={12} className='text-center'>
              <h3>2004-2005</h3>
              <Divider />
              <div>
                <h6>Dr. Jingcheng Xie</h6>
              </div>
              <br />
              <div>
                <h6>Dr. Sheeyan Fong</h6>
              </div>
              <br />
              <div>
                <h6>Dr. Sami Al-Eissa</h6>
              </div>
            </Col>
          </Row>
          <Row justify='center' gutter={[32, 32]} style={{ marginBottom: '32px' }}>
            <Col xl={12} className='text-center'>
              <h3>2003-2004</h3>
              <Divider />
              <div>
                <h6>Dr. Maher Al-Hejji</h6>
              </div>
              <br />
              <div>
                <h6>Dr. Ibrahim Assiri</h6>
              </div>
              <br />
              <div>
                <h6>Dr. Jean-François Chevalier</h6>
              </div>
              <br />
              <div>
                <h6>Dr. Ganesh Swamy</h6>
              </div>
            </Col>
          </Row>
          <Row justify='center' gutter={[32, 32]} style={{ marginBottom: '32px' }}>
            <Col xl={12} className='text-center'>
              <h3>2002-2003</h3>
              <Divider />
              <div>
                <h6>Dr. Abdurrahman Al Arjani</h6>
              </div>
              <br />
              <div>
                <h6>Dr. Brian Drew</h6>
              </div>
              <br />
              <div>
                <h6>Dr. Greg Harvey</h6>
              </div>
              <br />
              <div>
                <h6>Dr. Ken Thomas</h6>
              </div>
              <br />
              <div>
                <h6>Dr. Qureshi, Irfan</h6>
              </div>
            </Col>
          </Row>
          <Row justify='center' gutter={[32, 32]} style={{ marginBottom: '32px' }}>
            <Col xl={12} className='text-center'>
              <h3>2001-2002</h3>
              <Divider />
              <div>
                <h6>Dr. Andre Engelbrecht</h6>
              </div>
              <br />
              <div>
                <h6>Dr. Harry Jiang</h6>
              </div>
              <br />
              <div>
                <h6>Dr. Roger Cho</h6>
              </div>
            </Col>
          </Row>
          <Row justify='center' gutter={[32, 32]} style={{ marginBottom: '32px' }}>
            <Col xl={12} className='text-center'>
              <h3>2000-2001</h3>
              <Divider />
              <div>
                <h6>Dr. Wa’el Taha</h6>
              </div>
              <br />
              <div>
                <h6>Dr. Clayton Chan</h6>
              </div>
              <br />
              <div>
                <h6>Dr. Paul Thng</h6>
              </div>
            </Col>
          </Row>
          <Row justify='center' gutter={[32, 32]} style={{ marginBottom: '32px' }}>
            <Col xl={12} className='text-center'>
              <h3>1999-2000</h3>
              <Divider />
              <div>
                <h6>Dr. Bernard Rerri</h6>
              </div>
              <br />
              <div>
                <h6>Dr. Stephan du Plessis</h6>
              </div>
            </Col>
          </Row>
          <Row justify='center' gutter={[32, 32]} style={{ marginBottom: '32px' }}>
            <Col xl={12} className='text-center'>
              <h3>1998-1999</h3>
              <Divider />
              <div>
                <h6>Dr. Gary Dix</h6>
              </div>
            </Col>
          </Row>
          <Row justify='center' gutter={[32, 32]} style={{ marginBottom: '32px' }}>
            <Col xl={12} className='text-center'>
              <h3>1997-1998</h3>
              <Divider />
              <div>
                <h6>Dr. Yaser Behairy</h6>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Fragment>
  )
}
