import React from 'react'
import { Row, Col } from 'antd'

export const AboutProgram = () => {
  return (
    <div className='section' style={{ marginBottom: 0 }}>
      <h1 className='text-center'>About the Program</h1>
      <div className='section-inner' style={{ marginBottom: 0 }}>
        <Row align='middle'>
          <Col xl={12}>
            <picture>
              <source srcSet={'/images/about-surgical.webp'} type='image/webp' />
              <img
                srcSet={'/images/about-surgical.jpg'}
                alt='about1'
                className='about-img mobile-hidden'
              />
            </picture>
          </Col>
          <Col xl={12}>
            <div style={{ padding: '3rem' }}>
              <h3>Surgical Expertise</h3>
              <br />
              <span>
                The University of Calgary Spine Program is a multidisciplinary clinical
                and academic group focused on the care of individuals affected by
                conditions and diseases of the spine and spinal cord. Our group of
                academic spine surgeons perform over 1900 spine operations each year
                across adult and paediatric populations.
              </span>
            </div>
          </Col>
        </Row>
        <Row align='middle'>
          <Col xl={12}>
            <div style={{ padding: '3rem' }}>
              <h3>Fellowship Training Program</h3>
              <br />
              <span>
                The University of Calgary Spine group offers an AO sanctioned fellowship
                training program that combines expertise in both the orthopedic and
                neurosurgical treatment of spinal disorders.
              </span>
            </div>
          </Col>
          <Col xl={12}>
            <picture>
              <source srcSet={'/images/about-fellowship.webp'} type='image/webp' />
              <img
                srcSet={'/images/about-fellowship.jpg'}
                alt='about1'
                className='about-img mobile-hidden'
              />
            </picture>
          </Col>
        </Row>
        <Row align='middle'>
          <Col xl={12}>
            <picture>
              <source srcSet={'/images/about-research.webp'} type='image/webp' />
              <img
                srcSet={'/images/about-research.jpg'}
                alt='about1'
                className='about-img mobile-hidden'
              />
            </picture>
          </Col>
          <Col xl={12}>
            <div style={{ padding: '3rem' }}>
              <h3>Patient Focused Research Program</h3>
              <br />
              <span>
                Our surgeons maintain membership across both the Hotchkiss Brain Institute
                and the McCaig Institute for Bone and Joint Health at the University of
                Calgary. Collectively we are working to improve the lives of patients with
                spinal disorders.
              </span>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}
