import React, { Fragment, useRef, useState } from 'react'
import { Form, Button, InputNumber, Select, Slider, Radio, Divider, Row, Col } from 'antd'

const { Option } = Select

export const CAPPSForm = (props) => {
  const [showPHQ9Followup, setShowPHQ9Followup] = useState(false)
  const [calculatedPHQ9Score, setCalculatedPHQ9Score] = useState(0)
  const formRef = useRef(null)

  const onFinish = (values) => {
    if (values.phq_9 === 'no') values.phq_9_score = calculatedPHQ9Score

    props.calculateScore(values)
  }

  const onChange = (changedValues, allValues) => {
    let score = 0

    if (allValues.phq_9 === 'no') {
      for (let i = 1; i < 10; i++)
        if (allValues[`phq_9_${i}`]) score += allValues[`phq_9_${i}`]
    }

    setShowPHQ9Followup(allValues.phq_9)
    setCalculatedPHQ9Score(score)
  }

  return (
    <Form
      onFinish={onFinish}
      onValuesChange={onChange}
      layout='vertical'
      scrollToFirstError
      ref={formRef}
    >
      <Row gutter={[16, 16]}>
        <Col lg={4}>
          <Form.Item
            label='Age in Years'
            name='age'
            rules={[{ required: true, message: 'Please input your age in years' }]}
          >
            <InputNumber min={0} max={120} />
          </Form.Item>
        </Col>
        <Col lg={10}>
          <Form.Item
            label='Sex'
            name='sex'
            rules={[{ required: true, message: 'Please select a sex' }]}
          >
            <Select>
              <Option value='male'>Male</Option>
              <Option value='female'>Female</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col lg={10}>
          <Form.Item
            label={
              <span>
                <b>DAILY</b> opioid medication use
              </span>
            }
            name='opiod_daily'
            rules={[{ required: true, message: 'Please answer yes or no' }]}
          >
            <Select>
              <Option value='yes'>Yes</Option>
              <Option value='no'>No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Form.Item
            label={
              <span>
                Intensity of <b>OVERALL</b> preoperative neck or back pain on a scale of 0
                to 10
              </span>
            }
            name='pain'
            rules={[{ required: true, message: 'Please select a pain level' }]}
          >
            <Slider
              min={0}
              max={10}
              marks={{
                0: (
                  <div>
                    0<br />
                    <small>No pain</small>
                  </div>
                ),
                1: '1',
                2: '2',
                3: '3',
                4: '4',
                5: '5',
                6: '6',
                7: '7',
                8: '8',
                9: '9',
                10: (
                  <div>
                    10
                    <br />
                    <small>Unbearable</small>
                  </div>
                ),
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col lg={24}>
          <Form.Item
            label='Number of spinal level(s) involved in the planned surgery'
            name='number_spinal_levels'
            help='(e.g., L4-5 = 1 level, L3-S1 = 3 levels, C3-7 = 4 levels)'
            rules={[
              { required: true, message: 'Please enter the number of spinal levels' },
            ]}
          >
            <InputNumber min={0} max={50} />
          </Form.Item>
        </Col>
        <Col lg={24}>
          <Form.Item
            label='Fusion surgery'
            name='fusion_surgery'
            help='(a procedure in which a surgeon fuses two or more vertebrae in the spine using screws/plates/rods)'
            rules={[{ required: true, message: 'Please answer yes or no' }]}
          >
            <Select>
              <Option value='yes'>Yes</Option>
              <Option value='no'>No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col lg={24}>
          <Form.Item
            label="Do you know the patient's Patient Health Questionnaire (PHQ-9) depression score?"
            name='phq_9'
            rules={[{ required: true, message: 'Please answer yes or no' }]}
          >
            <Select>
              <Option value='yes'>Yes</Option>
              <Option value='no'>No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      {showPHQ9Followup ? (
        formRef.current && formRef.current.getFieldValue('phq_9') === 'yes' ? (
          <Form.Item
            label='Patient Health Questionnaire Score'
            name='phq_9_score'
            rules={[
              { required: true, message: "Please enter the patient's PHQ-9 score" },
            ]}
          >
            <InputNumber min={0} max={13} />
          </Form.Item>
        ) : (
          <Fragment>
            <div style={{ marginTop: '2rem', marginBottom: '0.5rem' }}>
              <h5 className='text-center'>Patient Health Questionnaire (PHQ-9)</h5>
              <b>
                Over the last 2 weeks, how often has the patient been bothered by any of
                the following problems?
              </b>
            </div>
            <Form.Item
              label='1) Little interest or pleasure in doing things'
              name='phq_9_1'
              rules={[{ required: true, message: 'Please answer' }]}
            >
              <Radio.Group>
                <Radio value={0}>Not at all</Radio>
                <Radio value={1}>Several days</Radio>
                <Radio value={2}>More than half the days</Radio>
                <Radio value={3}>Nearly every day</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label='2) Feeling down, depressed, or hopeless'
              name='phq_9_2'
              rules={[{ required: true, message: 'Please answer' }]}
            >
              <Radio.Group>
                <Radio value={0}>Not at all</Radio>
                <Radio value={1}>Several days</Radio>
                <Radio value={2}>More than half the days</Radio>
                <Radio value={3}>Nearly every day</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label='3) Trouble falling or staying asleep, or sleeping too much'
              name='phq_9_3'
              rules={[{ required: true, message: 'Please answer' }]}
            >
              <Radio.Group>
                <Radio value={0}>Not at all</Radio>
                <Radio value={1}>Several days</Radio>
                <Radio value={2}>More than half the days</Radio>
                <Radio value={3}>Nearly every day</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label='4) Feeling tired or having little energy'
              name='phq_9_4'
              rules={[{ required: true, message: 'Please answer' }]}
            >
              <Radio.Group>
                <Radio value={0}>Not at all</Radio>
                <Radio value={1}>Several days</Radio>
                <Radio value={2}>More than half the days</Radio>
                <Radio value={3}>Nearly every day</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label='5) Poor appetite or overeating'
              name='phq_9_5'
              rules={[{ required: true, message: 'Please answer' }]}
            >
              <Radio.Group>
                <Radio value={0}>Not at all</Radio>
                <Radio value={1}>Several days</Radio>
                <Radio value={2}>More than half the days</Radio>
                <Radio value={3}>Nearly every day</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label={
                <span style={{ height: '35px' }}>
                  6) Feeling bad about yourself – or that you are a failure or have let
                  yourself or your family down
                </span>
              }
              name='phq_9_6'
              rules={[{ required: true, message: 'Please answer' }]}
            >
              <Radio.Group style={{ paddingTop: '1rem' }}>
                <Radio value={0}>Not at all</Radio>
                <Radio value={1}>Several days</Radio>
                <Radio value={2}>More than half the days</Radio>
                <Radio value={3}>Nearly every day</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label='7) Trouble concentrating on things, such as reading the newspaper or watching television'
              name='phq_9_7'
              rules={[{ required: true, message: 'Please answer' }]}
            >
              <Radio.Group>
                <Radio value={0}>Not at all</Radio>
                <Radio value={1}>Several days</Radio>
                <Radio value={2}>More than half the days</Radio>
                <Radio value={3}>Nearly every day</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label={
                <span style={{ height: '35px' }}>
                  8) Moving or speaking so slowly that other people could have noticed? Or
                  the opposite – being so fidgety or restless that you have been moving
                  around a lot more than usual
                </span>
              }
              name='phq_9_8'
              rules={[{ required: true, message: 'Please answer' }]}
            >
              <Radio.Group style={{ paddingTop: '1rem' }}>
                <Radio value={0}>Not at all</Radio>
                <Radio value={1}>Several days</Radio>
                <Radio value={2}>More than half the days</Radio>
                <Radio value={3}>Nearly every day</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label='9) Thoughts that you would be better off dead or of hurting yourself in some way'
              name='phq_9_9'
              rules={[{ required: true, message: 'Please answer' }]}
            >
              <Radio.Group>
                <Radio value={0}>Not at all</Radio>
                <Radio value={1}>Several days</Radio>
                <Radio value={2}>More than half the days</Radio>
                <Radio value={3}>Nearly every day</Radio>
              </Radio.Group>
            </Form.Item>
            <b>Patient Health Questionnaire Score: {calculatedPHQ9Score}</b>
          </Fragment>
        )
      ) : null}
      <Divider />
      <Form.Item className='text-center'>
        <Button type='primary' htmlType='submit' shape='round' size='large'>
          Calculate Risk Score
        </Button>
      </Form.Item>
    </Form>
  )
}
