import React from 'react'
import { Row, Col } from 'antd'

import { hasWebP } from 'src/constants'

export const Banner = () => {
  const format = hasWebP ? 'webp' : 'jpg'

  return (
    <div
      style={{
        backgroundImage: `url(/images/banner.${format})`,
        backgroundSize: 'cover',
        minHeight: '60vh',
      }}
    >
      <Row align='middle' className='container' style={{ paddingTop: '11rem' }}>
        <Col>
          <h1 style={{ marginBottom: 0 }}>SURGICAL TREATMENT OF SPINAL DISORDERS</h1>
          <h4>
            Delivering personalized medicine for those living with spinal disorders{' '}
          </h4>
        </Col>
      </Row>
    </div>
  )
}
