import React from 'react'
import { Col, Card } from 'antd'
import { useNavigate } from 'react-router-dom'

const { Meta } = Card

export const TeamMember = (props) => {
  const navigate = useNavigate()
  const { fileName, name, credentials, location, link } = props

  const handleClick = () => {
    if (link) navigate(link)
  }

  return (
    <Col xl={8} lg={8} md={8} sm={16} xs={18}>
      <Card
        cover={
          <picture>
            <source srcSet={'/images/team/' + fileName + '.webp'} type='image/webp' />
            <img
              srcSet={'/images/team/' + fileName + '.jpg'}
              alt='portrait'
              className='team-img'
              style={{ cursor: link && 'pointer' }}
              onClick={handleClick}
            />
          </picture>
        }
      >
        <Meta
          title={name}
          description={
            <div>
              <span>{credentials}</span>
              {credentials && location && <br />}
              <span>{location}</span>
            </div>
          }
        />
      </Card>
    </Col>
  )
}
