import React, { Fragment } from 'react'
import { Button } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'

export const Login = () => {
  return (
    <Fragment>
      <div className='container page'>
        <h1 className='h1 text-center'>Log In</h1>
        <div className='section-inner text-center'>
          <p>
            Please ensure you are connected to the UofC VPN before continuing. <br />
            For more information and step-by-step guidance please refer to the following
            documentation:
          </p>
          <a
            href='https://ucalgary.service-now.com/it?id=kb_article&sys_id=bd4861dedb29bc506ad32637059619aa'
            target='_blank'
            rel='noopener noreferrer'
          >
            Admin VPN (MFA) - How To Connect on Windows
          </a>
          <br />
          <a
            href='https://ucalgary.service-now.com/it?id=kb_article&sys_id=dab03d12dbe9bc506ad3263705961955'
            target='_blank'
            rel='noopener noreferrer'
          >
            Admin VPN (MFA) - How To Connect on macOS
          </a>
          <br />
          <br />
          <br />
          <h4>I am connected to UofC VPN</h4>
          <Button
            type='text'
            shape='round'
            size='large'
            href={`${process.env.REACT_APP_UI_URL}/login`}
            icon={<ArrowRightOutlined />}
          >
            Continue to log in
          </Button>
        </div>
      </div>
    </Fragment>
  )
}
